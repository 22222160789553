import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import fr from 'vuetify/src/locale/fr';
import * as directives from 'vuetify/es5/directives';

Vue.use(Vuetify, {
  directives,
});

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },

    themes: {
      light: {
        primary: '#132448',
        sub_primary: '#32476F',
        pins: '#FFF9B5',
        secondary: '#FFF045',
        third: '#d3d9e5',
        grey: '#b3b3b3',
        accent: '#132448',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#e98020',

        pop_red: '#AF3F5F',
        pop_blue: '#25437C',
        pop_green: '#0F996D',
        pop_grey: '#B3B3B3',
        notif: '#E40D0D',
        postit: '#FFF9B5',
        tooltip: '#D3D9E5',
        white: '#ffffff',
        grey_e: '#eeeeee',
        grey_c: '#cccccc',
        grey_z: '#707070',
      },
      dark: {},
    },
  },
    lang: {
      locales: {fr},
      current: 'fr',
    },
  icons: {
    iconfont: 'mdi',
  },
});
