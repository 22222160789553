import {IPlaceSuggestion, IParsedPlaceSuggestion, IAddress} from '@/types';

export const addressHelper = {
    formatForStripe,
    parseRawData,
};

function parseRawData(place: IPlaceSuggestion): IParsedPlaceSuggestion {
    const address: IAddress|undefined = place.raw_data.address
    const placeSuggestion: IParsedPlaceSuggestion = {
        house_number: null,
        postal_code: null,
        street: null,
        city: null,
        country: null,
    };

    if (address) {
        placeSuggestion.house_number = address.houseNumber;
        placeSuggestion.postal_code = address.postalCode;
        placeSuggestion.street = address.street;
        placeSuggestion.city = address.city;
        placeSuggestion.country = address.countryName;
    }

    return placeSuggestion;
}

function formatForStripe(place: IParsedPlaceSuggestion): stripe.OwnerAddress {
    let line1 = '';

    if (place.house_number) {
        line1 = place.house_number;
    }

    if (place.street) {
        line1 = `${line1} ${place.street}`;
    }

    const stripeAddress: stripe.OwnerAddress = {
        line1: line1.trim(),
    };

    if (place.postal_code) {
        stripeAddress.postal_code = place.postal_code;
    }

    if (place.city) {
        stripeAddress.city = place.city;
    }

    if (place.country) {
        stripeAddress.country = place.country;
    }

    return stripeAddress;
}
